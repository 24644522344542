import React from "react"
import { Helmet } from "react-helmet"
import Banner from "../../blocs/banner"
import Header from "../../blocs/header"
import Footer from "../../blocs/footer"
//import Ariane from "../../blocs/ariane"

import { Script } from 'gatsby-script'

/*
const fil_ariane = [
    { name: 'Contact', href: '/contact/', current: false },
    { name: 'Contactez-nous', href: '', current: true },
  ]

  <Ariane filariane={fil_ariane} />
*/
export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Dubois-expertise.fr</title>
        <meta name="description" content="Vous avez des questions. Vous cherchez un expert en bâtiment ? Contactez Dubois Expertise." />
        <link rel="canonical" href="https://www.dubois-expertise.fr.fr/" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
        


        <div className="relative bg-white">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
          </div>
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Demandez un devis personnalisé</h1>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                Vous n’avez pas trouver les bonnes réponses à vos questions ? Vous êtes au bon endroit.
Dites-nous en plus sur vos besoins! Parce que chaque situation est particulière.<br />
<br />
Nous vous répondrons dans les plus brefs délais afin de convenir ensemble du périmètre d’intervention de votre expert.<br />
Vos besoins sont spéciaux, nos devis sont personnalisés et gratuits…<br />
<br />
Dubois Expertise, votre expert bâtiment en Auvergne Rhône-Alpes, basé à Caluire et cuire, à proximité de Lyon.<br />
<br />
Expertises fissure, humidité, bâtiment, Avis avant acquisition, service de télé-expertise..<br />
Découvrez nos missions ici.<br />
<br />
Vous pouvez aussi nous joindre au 06 29 65 40 17, ou par email directement à matthieu[AROBASE]dubois-expertise.fr<br />
<br />
                </p>
                <h2 className="text-1xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                Besoin d'une expertise en bâtiment, pathologies de la construction, fissure, humidité, expertise avant achat?
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                Vous souhaitez être accompagné dans vos projets de construction? Etats des lieux? Formation en pathologies de la construction? Recherche d'un expert bâtiment en Auvergne Rhône Alpes ou ailleurs en France. Problématiques de présence d’humidité, de fissures. Litiges de la construction, malfaçons dans une maison, un appartement, un local commercial.
Propriétaires, ou locataires, agents immobiliers, faites appel à un expert neutre pour solutionner vos problématiques. Contactez Dubois-Expertise.
                </p>
              </div>
            </div>
            <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <form action="https://app.koudepouce.fr/duboisexpertise/contact-devis/" method="POST" className="grid grid-cols-1 gap-y-6">
                  <div>
                    <label htmlFor="nom" className="sr-only">
                      Nom
                    </label>
                    <input
                      type="text"
                      name="nom"
                      id="nom"
                      autoComplete="name"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      placeholder="Nom *"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="prenom" className="sr-only">
                      Prénom
                    </label>
                    <input
                      type="text"
                      name="prenom"
                      id="prenom"
                      autoComplete="name"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      placeholder="Prénom"
                    />
                  </div>
                  <div>
                    <label htmlFor="cp" className="sr-only">
                      Code postal
                    </label>
                    <input
                      type="text"
                      name="cp"
                      id="cp"
                      autoComplete="name"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      placeholder="Code postal *"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="telephone" className="sr-only">
                      Téléphone
                    </label>
                    <input
                      type="text"
                      name="telephone"
                      id="telephone"
                      autoComplete="tel"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      placeholder="Téléphone *"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  <div>
                  <select
                     className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" 
                     name="specialite" 
                     id="specialite"
                     required
                     >
                      <option value="">Mission recherchée</option>
                      <option value="Expertise bâtiment">Expertise bâtiment</option>
                      <option value="Expertise humidité">Expertise humidité</option>
                      <option value="Expertise fissure">Expertise fissure</option>
                      <option value="Avis technique">Avis technique</option>
                      <option value="Télé-expertise">Télé-expertise</option>
                      <option value="Je ne sais pas">Je ne sais pas</option>
                  </select>
                  </div>
                  <div>
                    <label htmlFor="message" className="sr-only">
                      Soyons plus précis sur vos attentes, votre besoin.
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                      placeholder="Message *"
                      defaultValue={''}
                      required
                    />
                  </div>
                  <div>
                    <div className="mt-1">
                      <input className="jCaptcha py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" type="text" placeholder="Résultat" required />
                    </div>
                  </div>
                  <div>
                      <label htmlFor="message" className="sr-only">
                        CGU
                      </label>
                      <input 
                          type="checkbox" 
                          id="cgu" 
                          name="cgu" 
                          className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" 
                          required 
                      /> J'accepte les <a href="/legal/cgu/" className="text-blue-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a> *
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
                <Script src="../../jCaptcha.js" />
                <p className="mt-3 text-xs leading-6 text-gray-500">
                Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par le site Dubois Expertise.fr et édité par la société Dubois Expertise visant à traiter votre demande. Elles sont conservées pendant au moins 3 ans et sont destinées au service marketing et au service commercial. Nos mentions légales sont ici.<br />
    Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : Dubois Expertise, Service de la protection des données, 31 Chemin de Crépieux, 69300 Caluire et Cuire, ou par e-mail sur matthieu[AROBASE]dubois-expertise.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
                </p>
              </div>
            </div>
          </div>
        </div>


        
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}